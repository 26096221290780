/*
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Internal dependencies
 */

/**
 * Assets
 */
import BMWLogo from './logo-bmw.svg';
import CourierLogo from './logo-courier.svg';
import EBSLogo from './logo-ebs.svg';
import FacebookLogo from './logo-facebook.svg';
import GALogo from './logo-game-analytics.svg';
import DekodeLogo from './logo-dekode.svg';
import ImagineerLogo from './logo-imagineear.svg';
import InternationalThoroughbredLogo from './logo-international-thoroughbred.svg';
import JuddmonteLogo from './logo-juddmonte.svg';
import NBLogo from './logo-national-library-of-norway.svg';
import NHSLogo from './logo-nhs.svg';
import SpendologyLogo from './logo-spendology.svg';
import StartRiteLogo from './logo-start-rite.svg';
import TGIFridaysLogo from './logo-tgi-fridays.svg';
import TheLegal500Logo from './logo-the-legal-500.svg';
import UmbroLogo from './logo-umbro.svg';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const ClientList = ( { topRowOnly, twoCol } ) => {
    const classes = classNames( 'client-list-logo col-6', {
        'col-xs-4 col-md-3': ! twoCol,
    } );

    return (
        <div>
            { ! topRowOnly ? (
                <div className="client-list row justify-content-xs-center">
                    <div className={ classes }>
                        <NHSLogo />
                    </div>
                    <div className={ classes }>
                        <FacebookLogo />
                    </div>
                    <div className={ classes }>
                        <UmbroLogo />
                    </div>
                    <div className={ classes }>
                        <TGIFridaysLogo />
                    </div>
                    <div className={ classes }>
                        <StartRiteLogo />
                    </div>
                    <div className={ classes }>
                        <TheLegal500Logo />
                    </div>
                    <div className={ classes }>
                        <CourierLogo />
                    </div>
                    <div className={ classes }>
                        <BMWLogo />
                    </div>
                    <div className={ classes }>
                        <GALogo />
                    </div>
                    <div className={ classes }>
                        <InternationalThoroughbredLogo />
                    </div>
                    <div className={ classes }>
                        <DekodeLogo />
                    </div>
                    <div className={ classes }>
                        <JuddmonteLogo />
                    </div>
                    <div className={ classes }>
                        <SpendologyLogo />
                    </div>
                    <div className={ classes }>
                        <NBLogo />
                    </div>
                    <div className={ classes }>
                        <EBSLogo />
                    </div>
                    <div className={ classes }>
                        <ImagineerLogo />
                    </div>
                </div>
            ) : (
                <div className="client-list row justify-content-xs-center">
                    <div className={ classes }>
                        <NHSLogo />
                    </div>
                    <div className={ classes }>
                        <UmbroLogo />
                    </div>
                    <div className={ classes }>
                        <TGIFridaysLogo />
                    </div>
                    <div className={ classes }>
                        <BMWLogo />
                    </div>
                </div>
            ) }
        </div>
    );
};

ClientList.propTypes = {
    topRowOnly: PropTypes.bool,
    twoCol: PropTypes.bool,
};

ClientList.defaultProps = {
    topRowOnly: false,
    twoCol: false,
};

export default ClientList;
